<template>
  <el-container>
    <el-row style="width: 100%">
      <el-row>
        <el-col :span="24">
          <div class="cardTitle">检查表列表</div>
          <el-button type="primary" @click="createSurvey()" class="cardTitleBtn"  v-if="userInfo.role ==1"
            >新建检查表</el-button
          >
        </el-col>
      </el-row>

      <el-row v-if="userInfo.role ==3">
        <el-col :span="12">
          <el-select style="width:40%;padding-left:40px" @change="getSurveyList(1)" v-model="selectedCommunityGuid" placeholder="请选择">
            <el-option
              v-for="item in allCommunityList"
              :key="item.guid"
              :label="
                item.districtName +
                '-' +
                item.streetName +
                '-' +
                item.name
              "
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="userInfo.role == 1">
        <el-col :span="8">
            <el-input style="width:92%;padding-left:40px" v-model="searchName" :placeholder="'问卷名称'"></el-input>
        </el-col>
        <el-col :span="8" style="padding-left:40px">
          <el-button style="" @click="getSurveyList(1)" type="primary">搜索</el-button>
        </el-col>
      </el-row>

      <el-col :span="24" class="card_style">
        <el-table :data="surveyList" style="width: 100%">
          <el-table-column sortable prop="name" label="名称"></el-table-column>
          <el-table-column sortable prop="patrolTimes" width="100px" label="巡检次数"></el-table-column>
          <el-table-column sortable label="简介">
            <template slot-scope="scope">
              <span>{{editContent(scope.row.content)}}</span>
            </template>
          </el-table-column>
          <el-table-column sortable prop="updateTime" width="200px" label="最近更新时间"></el-table-column>
          <el-table-column sortable label="操作" width="300px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="getDetail(scope.row.guid,scope.row.name,scope.row.content,scope.row.patrolTimes,scope.row.communityList)" v-if="userInfo.role ==1">基础信息</el-button>
              <el-button type="primary" size="small" @click="getProblems(scope.row.guid)">详情</el-button>
              <el-button type="info" size="small" @click="copyQuestion(scope.row.guid)">复制</el-button>
              <el-button type="danger" size="small" @click="onDelete(scope.row.guid, scope.row.name)" v-if="userInfo.role ==1">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="24">
        <div class="block" style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="recordList.nowPageNum"
            :page-sizes="[ 20, 40, 80]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="recordList.totalNum"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="基础信息" :visible.sync="dialogVisible" width="50%">
      <el-form
        ref="form"
        label-width="100px"
        label-position="left"
        :model="itemForm"
      >
        <el-row>
          <el-form-item label="名称">
            <el-input v-model="itemForm.name"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="巡检次数">
            <el-input v-model="itemForm.patrolTimes"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="简介">
            <el-input
              type="textarea"
              rows="5"
              v-model="itemForm.content"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-form-item label="分发小区" v-if="false">
          <el-card class="box-card">
            <i
              style="float: right"
              @click="addOption()"
              class="el-icon-circle-plus-outline"
            ></i>
            <div
              v-for="o in itemForm.communityList"
              :key="o.guid"
              class="text item"
              style="margin-top: 20px"
            >
              <el-row>
                <el-col :span="2">
                  <span>{{o.provinceName}} </span>
                </el-col>
                <el-col :span="1">
                  <span>-</span>
                </el-col>
                <el-col :span="2">
                  <span>{{o.cityName}} </span>
                </el-col>
                <el-col :span="1">
                  <span>-</span>
                </el-col>
                <el-col :span="2">
                  <span>{{o.districtName}} </span>
                </el-col>
                <el-col :span="1">
                  <span>-</span>
                </el-col>
                <el-col :span="3">
                  <span>{{o.streetName}}</span>
                </el-col>
                <el-col :span="1">
                  <span>-</span>
                </el-col>
                <el-col :span="3">
                  <span>{{o.committeeName}} </span>
                </el-col>
                <el-col :span="1">
                  <span>-</span>
                </el-col>
                <el-col :span="5">
                  <span>{{o.name}}</span>
                </el-col>
                <el-col :span="1">
                  <i
                    style="margin-left: 10px"
                    class="el-icon-remove-outline"
                    @click="delRow(o.guid)"
                  ></i>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveOrUpdate()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择小区"
      :visible.sync="communityDialogVisible"
      width="50%"
    >
      <el-row>
        <el-col :span="8">
          <el-select
            v-model="provinceGuid"
            @change="provinceChange()"
            placeholder="省"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="cityGuid"
            @change="getDistrictList()"
            placeholder="市"
          >
            <el-option
              v-for="item in cityList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="districtGuid"
            @change="getStreetList()"
            placeholder="区"
          >
            <el-option
              v-for="item in districtList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <br>
      <el-row>
        <el-col :span="8">
          <el-select v-model="streetGuid" @change="getCommitteeList()" placeholder="街道">
            <el-option
              v-for="item in streetList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"

            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
           <el-select
            v-model="committeeGuid"
            @change="getCommuntyList()"
            placeholder="居委会"
          >
            <el-option
              v-for="item in committeeList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
           <el-select
            v-model="communityGuid"
            @change="communityChange()"
            placeholder="小区"
          >
            <el-option
              v-for="item in communityList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="communityDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddOption()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<style>
  .moreBtn{
    float: right;
    color: #409EFF;
    cursor: pointer;
  }
  .text {
    font-size: 14px;
  }
  .cardBody{
    height: 60px;
    overflow: hidden;
    font-size: 14px;
    color: rgb(88, 88, 88);
  }
  .card_style {
    margin-top: 20px;
    padding-left: 40px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
</style>
<script>
  export default {
    data() {
      return {
        userInfo: {},
        surveyList: [],
        recordList: {},
        dialogVisible: false,
        itemForm: {
          guid: "",
          name: "",
          content: "",
          patrolTimes: 0,
          communityList: [],
          communityGuidList:[],
          districtGuidList:[],
          streetGuidList:[]
        },
        //
        provinceList: [],
        cityList: [],
        committeeList: [],
        communityList: [],
        districtList: [],
        streetList: [],
        communityDialogVisible: false,
        provinceGuid: "",
        provinceName: "",
        cityGuid: "",
        cityName: "",
        districtGuid: "",
        districtName: "",
        streetGuid: "",
        streetName: "",
        committeeGuid: "",
        committeeName: "",
        communityGuid: "",
        communityName: "",
        communityGuidList: [],
        //
        pageSize: 20,
        allCommunityList: [],
        selectedCommunityGuid: "",
        searchName:"",
      };
    },
    mounted() {
      let self = this;
      self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      self.getSurveyList(1);
      // self.getDistrictList();
      self.getProvinceList();
      self.getAllCommunityList();
    },
    methods: {
      getSurveyList(pageNum) {
        let self = this;
        let url = self.userInfo.role == 1?"/patrol-survey/page?pageSize=" +self.pageSize +"&pageNum=" +(pageNum || 1)+"&nameLike="+self.searchName:"/patrol-survey/list/estate?communityGuid="+self.selectedCommunityGuid;
        self.$axios
          .get(
            url,
            {
              headers: { token: self.userInfo.token },
            }
          )
          .then(function (response) {
            self.surveyList = self.userInfo.role == 1? response.data.data.records:response.data.data;
            self.recordList = response.data.data;
          })
          .catch(function (error) {
            self.$message({
              message: "巡检问卷获取失败：" + error.response.data.message,
              type: "warning",
            });
          });
      },
      getDetail(guid, name, content, patrolTimes, communityList) {
        this.dialogVisible = true;
        this.itemForm.guid = guid;
        this.itemForm.name = name;
        this.itemForm.content = content;
        this.itemForm.patrolTimes = patrolTimes;
        this.itemForm.communityList = communityList;
        communityList.forEach((element) => {
          this.communityGuidList.push(element.guid);
        });
      },
      createSurvey() {
        this.dialogVisible = true;
        this.itemForm = {
          guid: "",
          name: "",
          content: "",
          patrolTimes: 0,
          communityList: [],
          communityGuidList:[],
          districtGuidList:[],
          streetGuidList:[]
        };
        this.communityGuidList = [];
      },
      saveOrUpdate() {
        let self = this;
        self.itemForm.communityGuidList = self.communityGuidList;
        self.$axios
          .post("/patrol-survey/saveOrUpdate", self.itemForm, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.$message({
              message: "操作成功",
              type: "success",
            });
            if (response.data.status) {
              self.getSurveyList(1);
              self.dialogVisible = false;
            }
          })
          .catch(function (error) {
            self.$message({
              message: "保存失败" + error.response.data.message,
              type: "warning",
            });
          });
      },
      onDelete(guid, title) {
        let self = this;
        self
          .$confirm("确定删除" + title + "吗？")
          .then(() => {
            self.$axios
              .delete("/patrol-survey/delete", {
                data: { guid: guid },
                headers: { token: self.userInfo.token },
              })
              .then(function () {
                self.$message({
                  message: "删除成功",
                  type: "success",
                });
                self.getSurveyList(1);
              })
              .catch(function (error) {
                self.$message({
                  message: "删除失败" + error.response.data.message,
                  type: "warning",
                });
              });
          })
          .catch(() => {});
      },
      delRow(guid) {
        let self = this;
        self.itemForm.communityList = self.itemForm.communityList.filter(
          (value) => value.guid !== guid
        );
        self.communityGuidList = self.communityGuidList.filter(
          (value) => value !== guid
        );
      },
      addOption() {
        let self = this;
        self.provinceGuid = "";
        self.provinceName = "";
        // self.provinceList = [];
        self.cityGuid = "";
        self.cityName = "";
        self.cityList = [];
        self.districtGuid = "";
        self.districtName = "";
        self.districtList = [];
        self.streetList = [];
        self.streetGuid = "";
        self.streetName = "";
        self.committeeGuid = "";
        self.committeeName = "";
        self.committeeList = [];
        self.communityGuid = "";
        self.communityName = "";
        self.communityList = [];
        self.communityDialogVisible = true;
      },
      doAddOption() {
        let self = this;
        if (self.communityGuidList.indexOf(self.communityGuid) > -1) {
          self.$message({
            message: "请勿添加重复项",
            type: "warning",
          });
          return;
        }
        if (self.communityGuid == "") {
          self.$message({
            message: "问卷分发区域必须精确到小区",
            type:"warning",
          });
          return ;
        }
        let params = {
          provinceGuid: self.provinceGuid,
          provinceName: self.provinceName,
          cityGuid: self.cityGuid,
          cityName: self.cityName,
          districtName: self.districtName,
          districtGuid: self.districtGuid,
          streetName: self.streetName,
          streetGuid: self.streetGuid,
          committeeGuid: self.committeeGuid,
          committeeName: self.committeeName,
          name: self.communityName,
          guid: self.communityGuid
        };
        self.itemForm.communityList.push(params);

        if(self.communityGuid!=""){
          self.itemForm.communityGuidList.push(self.communityGuid);
        }
        else if(self.streetGuid!=""){
          self.itemForm.streetGuidList.push(self.streetGuid);
        }else if(self.districtGuid!=""){
          self.itemForm.districtGuidList.push(self.districtGuid);
        }
        self.communityDialogVisible = false;
      },

      getProblems(guid) {
        let self = this;
        let name = self.userInfo.role == 1? "patrol_survey_problem":"patrol_survey_problem_list"
        self.$router.push({
          name: name,
          query: { guid: guid,communityGuid:self.selectedCommunityGuid },
        });
      },
      getAllCommunityList(){
        let self = this;
        let url = self.userInfo.role ==1?"/community/list":"/community/list/estate";
        self.$axios
          .get(url, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.allCommunityList = response.data.data;
            if(self.allCommunityList.length>0){
              self.selectedCommunityGuid = self.allCommunityList[0].guid;
              self.getSurveyList(1);
            }
          })
          .catch(function (error) {
            self.$message({
              message: "小区列表获取失败：" + error.response.data.message,
              type: "warning",
            });
          });
      },
      // 分发小区 START
      getProvinceList() {
        let self = this;
        self.$axios.get("/province/list", {
          headers: { token: self.userInfo.token },
        }).then(function(response) {
          self.provinceList = response.data.data;
        }).catch(function (error) {
          self.$message({
            message: "省列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
      },
      provinceChange() {
        let self = this;
        self.getCityList(self.provinceGuid);
        self.provinceName = self.$underscore.find(self.provinceList, function(_el) {
          return _el.guid == self.provinceGuid;
        }).name;
      },
      getCityList() {
        let self = this;
        self.$axios.get("/city/list?provinceGuid=" + self.provinceGuid, {
          headers: { token: self.userInfo.token },
        }).then(function(response) {
          self.cityList = response.data.data;
        }).catch(function (error) {
          self.$message({
            message: "城市列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
      },
      getDistrictList() {
        let self = this;
        self.$axios
          .get("/district/list?cityGuid=" + self.cityGuid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.cityName = self.$underscore.find(self.cityList, function(_el) {
              return _el.guid == self.cityGuid;
            }).name;
            self.districtList = response.data.data;
            ("self.districtList = ", self.districtList)
          })
          .catch(function (error) {
            self.$message({
              message: "区列表获取失败：" + error.response.data.message,
              type: "warning",
            });
          });
      },
      getStreetList() {
        let self = this;
        self.$axios
          .get("/street/list?districtGuid=" + self.districtGuid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.streetList = response.data.data;
            self.districtName = self.$underscore.find(self.districtList, function(_el) {
              return _el.guid == self.districtGuid;
            }).name;
          })
          .catch(function (error) {
            self.$message({
              message: "街道列表获取失败：" + error.response.data.message,
              type: "warning",
            });
          });
      },
      getCommitteeList() {
        let self = this;
        self.$axios
          .get("/committee/list?streetGuid=" + self.streetGuid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.committeeList = response.data.data;
            self.streetName = self.$underscore.find(self.streetList, function(_el) {
              return _el.guid == self.streetGuid;
            }).name;
          })
          .catch(function (error) {
            self.$message({
              message: "居委会列表获取失败：" + error.response.data.message,
              type: "warning",
            });
          });
      },
      getCommuntyList() {
        let self = this;
        self.$axios
          .get("/community/list?committeeGuid=" + self.committeeGuid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.communityList = response.data.data;
            self.committeeName = self.$underscore.find(self.committeeList, function(_el) {
              return _el.guid == self.committeeGuid;
            }).name;
          })
          .catch(function (error) {
            self.$message({
              message: "社区列表获取失败：" + error.response.data.message,
              type: "warning",
            });
          });
      },
      communityChange() {
        let self = this;
        self.communityName = self.$underscore.find(self.communityList, function(_el) {
          return _el.guid == self.communityGuid;
        }).name;
      },
      // 分发小区 END

      handleSizeChange(val) {
        this.pageSize = val * 1;
        this.getSurveyPageNum()
      },
      handleCurrentChange(val) {
        this.getSurveyPageNum(val)
      },
      getSurveyPageNum(val) {
        this.getSurveyList(val);
      },
      // 简介
      editContent(str) {
         if(str.length > 13) {
           return str.slice(0,13) + "..."
         } else {
           return str
         }
      },
      // 复制
      copyQuestion(guid) {
        let self = this
        
        self.$axios.post('/patrol-survey/clone',{
          guid: guid
        },{
          headers: { token: self.userInfo.token }
        })
        .then(function(res) {
          self.$message({
            type: 'success',
            message: res.data.message
          })
          self.getSurveyList(1);
        })
        .catch(function() {
          self.$message({
            type: 'warning',
            message: '复制失败'
          })
        })
      }
    },
  };
</script>

<style scoped>
</style>
